import React, { useState, useEffect } from "react";
import "./App.css";
import UserProfile from "./components/UserProfile/UserProfile";

function App() {
  const [activity, setActivity] = useState({
    title: "",
    description: "",
    state: "",
    largeImageKey: "",
    smallImageKey: "",
  });

  useEffect(() => {
    // Fetch data from your API endpoint
    fetch("https://discord-api-rho.vercel.app/activity")
      .then((response) => response.json())
      .then((data) => {
        // Process the fetched data and map it to your activity structure
        setActivity({
          title: data.name,
          description: data.details,
          state: data.state,
          largeImageKey: data.largeImage, // Use largeImage if available
          smallImageKey: data.smallImage, // Use smallImage if available
        });
      })
      .catch((error) => {
        console.error("Error fetching activity data:", error);
        // Handle error cases, e.g., set default activity values
      });
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  return (
    <div className="App">
      <UserProfile
        avatar_img="/marsha.jpg"
        username="Hanif"
        discriminator="1231"
        status="online"
        createdAt={Date.now()}
        bannerColor="ffffff"
        aboutMe="Ndas Ku Mumet"
        activity={activity} // Pass the fetched activity data
        badges={[
          { name: "HypeSquad Bravery", imgLink: "https://discordresources.com/img/hypesquadbravery.svg" },
          { name: "Active Developer", imgLink: "https://discordresources.com/img/activedeveloper.svg" },
          { name: "Originally known as", imgLink: "https://discordresources.com/img/username.png" },
        ]}
      />
    </div>
  );
}

export default App;
